import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { sctgFormatChapterFile } from "../../../store/apps/subler-chapter-text-generator/subler-chapter-text-generator-serialize";
import { sublerChapterTextGeneratorSelector } from "../../../store/apps/subler-chapter-text-generator/subler-chapter-text-generator-slice";
import { ModalType, pushModal } from "../../../store/modals/modals-slice";
import { Button, LinePosition } from "../../gui/atoms/button";
import { PostContent } from "../../gui/atoms/post-content";
import { Toolbar } from "../../gui/molecules/toolbar";

const StyledSctgChaptersListWrapper = styled.div``;

export function SctgChaptersList() {
  const { sctgChapters, sctgVideoTitle } = useAppSelector(
    sublerChapterTextGeneratorSelector
  );
  const isEmpty = sctgChapters.length === 0;
  const dispatch = useAppDispatch();
  const downloadChapterTextFile = () => {
    const link = document.createElement("a");
    link.download = `${sctgVideoTitle?.replaceAll(".", "-")} - chapters.txt`;
    link.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
      sctgFormatChapterFile(sctgChapters, { watermark: true })
    )}`;
    link.click();
    link.remove();
  };
  return (
    <StyledSctgChaptersListWrapper>
      <PostContent>
        <pre style={{ margin: 0 }}>{sctgFormatChapterFile(sctgChapters)}</pre>
      </PostContent>
      <Toolbar>
        <Button
          disabled={isEmpty}
          faIcon={{ icon: faDownload }}
          onClick={downloadChapterTextFile}
          linePosition={LinePosition.BOTTOM}
        >
          Download Chapter Text File
        </Button>
        <Button
          disabled={isEmpty}
          faIcon={{ icon: faTrashAlt }}
          onClick={() => {
            dispatch(
              pushModal({
                type: ModalType.APPS_SCTG_REMOVE_CHAPTERS,
                options: {},
              })
            );
          }}
          linePosition={LinePosition.BOTTOM}
        >
          Remove Chapters...
        </Button>
      </Toolbar>
    </StyledSctgChaptersListWrapper>
  );
}
