import {
  faAdd,
  faBackwardStep,
  faForwardStep,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled, { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { sctgFormatTimestamp } from "../../../store/apps/subler-chapter-text-generator/subler-chapter-text-generator-serialize";
import {
  insertOrUpdateSctgChapter,
  setSctgEditingChapterName,
  sublerChapterTextGeneratorSelector,
} from "../../../store/apps/subler-chapter-text-generator/subler-chapter-text-generator-slice";
import { Button } from "../../gui/atoms/button";
import { Toolbar } from "../../gui/molecules/toolbar";

interface SctgEditorTimePickerProps {
  addCurrentTimeMs: (durationMs: number) => void;
}

export function SctgEditorTimePicker(props: SctgEditorTimePickerProps) {
  const { sctgEditingChapterName, sctgChapters, sctgVideoPlaybackTimestampMs } =
    useAppSelector(sublerChapterTextGeneratorSelector);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isOverwritingChapter = sctgChapters.some(
    (c) => c.timestampMs === sctgVideoPlaybackTimestampMs
  );
  return (
    <StyledSctgEditorTimePicker>
      <Toolbar align="center">
        {/* TODO: implement chapter-based skipping */}
        <Button disabled={true} faIcon={{ icon: faBackwardStep }}>
          {false}
        </Button>
        {[-1, -0.1, -0.01, -0.001, 0.001, 0.01, 0.1, 1].map((dur) => (
          <Button
            key={dur}
            onClick={() => {
              props.addCurrentTimeMs(dur * 1000);
            }}
          >
            {dur > 0 ? `+${dur}` : `-${Math.abs(dur)}`}
          </Button>
        ))}
        <Button disabled={true} faIcon={{ icon: faForwardStep }}>
          {false}
        </Button>
      </Toolbar>
      <Toolbar align="center">
        {/* TODO: open modal to enter custom timestamp? */}
        <div style={{ padding: theme.spacings.buttons.paddingHorizontal }}>
          {sctgFormatTimestamp(sctgVideoPlaybackTimestampMs)}
        </div>
        {/* TODO: styled input */}
        <input
          type="text"
          placeholder="Chapter Name (required)"
          value={sctgEditingChapterName}
          onChange={(e) => dispatch(setSctgEditingChapterName(e.target.value))}
        />
        <Button
          faIcon={{
            icon: isOverwritingChapter ? faPen : faAdd,
          }}
          onClick={() => {
            dispatch(
              insertOrUpdateSctgChapter({
                label: sctgEditingChapterName,
                timestampMs: sctgVideoPlaybackTimestampMs,
              })
            );
            dispatch(setSctgEditingChapterName(""));
          }}
          disabled={sctgEditingChapterName.length === 0}
        >
          {isOverwritingChapter ? "Update Chapter" : "Add Chapter"}
        </Button>
      </Toolbar>
    </StyledSctgEditorTimePicker>
  );
}

const StyledSctgEditorTimePicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${(props) => props.theme.spacings.panels.gap}px;
  margin-top: ${(props) => props.theme.spacings.panels.padding}px;
  margin-bottom: ${(props) => props.theme.spacings.panels.padding}px;
`;
