import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { sublerChapterTextGeneratorSelector } from "../../../store/apps/subler-chapter-text-generator/subler-chapter-text-generator-slice";
import { ProgramWindowPadding } from "../../gui/organisms/program-window/program-window";
import { SctgEditorToolbar } from "../organisms/sctg-editor-toolbar";
import { SctgChaptersList } from "../organisms/sctg-chapters-list";
import {
  SctgEditorVideoViewer,
  SctgEditorVideoViewerRef,
} from "../organisms/sctg-editor-video-viewer";
import { SctgEditorTimePicker } from "../organisms/sctg-editor-time-picker";

export function SctgEditor() {
  const { sctgVideoUrl } = useAppSelector(sublerChapterTextGeneratorSelector);
  const videoViewerRef = React.useRef<SctgEditorVideoViewerRef>(null);
  const addCurrentTimeMs = React.useCallback(
    (durationMs: number) => {
      videoViewerRef.current?.addCurrentTimeMs(durationMs);
    },
    [videoViewerRef.current]
  );
  return (
    <>
      <SctgEditorToolbar />
      <ProgramWindowPadding>
        <SctgEditorVideoViewer ref={videoViewerRef} />
        {sctgVideoUrl && (
          <SctgEditorTimePicker addCurrentTimeMs={addCurrentTimeMs} />
        )}
        {sctgVideoUrl && <SctgChaptersList />}
      </ProgramWindowPadding>
    </>
  );
}
