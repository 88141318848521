import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { sublerChapterTextGeneratorSelector } from "../../../store/apps/subler-chapter-text-generator/subler-chapter-text-generator-slice";
import { ProgramWindow } from "../../gui/organisms/program-window/program-window";
import { SctgEditor } from "../templates/sctg-editor";

export function SctgEditorWindow() {
  const { sctgVideoTitle } = useAppSelector(sublerChapterTextGeneratorSelector);
  return (
    <ProgramWindow
      windowId="sctg-editor"
      title={
        sctgVideoTitle
          ? `${sctgVideoTitle} - Subler Chapter Text Generator`
          : "Subler Chapter Text Generator"
      }
      applyContentPadding={false}
    >
      <SctgEditor />
    </ProgramWindow>
  );
}
