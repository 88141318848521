import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowUpRightFromSquare,
  faPhotoFilm,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setSctgVideoFromFile,
  sublerChapterTextGeneratorSelector,
} from "../../../store/apps/subler-chapter-text-generator/subler-chapter-text-generator-slice";
import { ModalType, pushModal } from "../../../store/modals/modals-slice";
import { Button, LinkButton } from "../../gui/atoms/button";
import { FileInputButton } from "../../gui/atoms/file-input-button";
import { Toolbar, ToolbarSpacer } from "../../gui/molecules/toolbar";

export function SctgEditorToolbar() {
  const dispatch = useAppDispatch();
  const onVideoFileChange = React.useCallback(
    (file: File | undefined) => {
      if (file) {
        dispatch(setSctgVideoFromFile(file));
      }
    },
    [dispatch, setSctgVideoFromFile]
  );
  const { sctgVideoUrl } = useAppSelector(sublerChapterTextGeneratorSelector);
  const hasVideoSelected = !!sctgVideoUrl;
  return (
    <Toolbar>
      <FileInputButton
        accept="video/mp4"
        onChange={onVideoFileChange}
        faIcon={{ icon: faPhotoFilm }}
      >
        {hasVideoSelected ? "Change Video" : "Select Video"}
      </FileInputButton>
      <ToolbarSpacer />
      <LinkButton
        faIcon={{ icon: faArrowUpRightFromSquare }}
        link={{
          to: "https://subler.org/",
          target: "_blank",
        }}
      >
        Subler Website
      </LinkButton>
      <Button
        onClick={() => {
          dispatch(pushModal({ type: ModalType.APPS_SCTG_ABOUT, options: {} }));
        }}
        faIcon={{ icon: faQuestionCircle }}
      >
        About
      </Button>
    </Toolbar>
  );
}
