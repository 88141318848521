import * as React from "react";
import { HeadFC } from "gatsby";
import { DesktopId } from "../../../app/desktops";
import { DesktopLayoutBasicColumn } from "../../../components/layout/desktop-layouts/desktop-layout-basic-column";
import { SctgEditorWindow } from "../../../components/subler-chapter-text-generator/layouts/sctg-editor-window";

export default function SublerChapterTextGeneratorPage() {
  return (
    <DesktopLayoutBasicColumn
      desktopId={DesktopId.APPS_SCTG}
      canWindowsRearrange={true}
    >
      {/* TODO: iterate over open documents */}
      <SctgEditorWindow />
    </DesktopLayoutBasicColumn>
  );
}

export const Head: HeadFC = () => (
  <title>Subler Chapter Text Generator | simontang.dev</title>
);
