import React from "react";
import ReactDOM from "react-dom";
import { Button, ButtonProps } from "./button";

interface FileInputButtonProps extends Pick<ButtonProps, "faIcon"> {
  accept: HTMLInputElement["accept"];
  children: React.ReactNode;
  onChange: HiddenFileInputProps["onChange"];
}

// TODO: drag-and-drop event listener
export function FileInputButton(props: FileInputButtonProps) {
  const inputRef = React.useRef<HiddenFileInputRef>(null);
  const onClick = React.useCallback(() => {
    inputRef.current?.trigger();
  }, [inputRef.current]);
  return (
    <Button onClick={onClick} faIcon={props.faIcon}>
      {props.children}{" "}
      <HiddenFileInput
        ref={inputRef}
        accept={props.accept}
        onChange={props.onChange}
      />
    </Button>
  );
}

interface HiddenFileInputRef {
  trigger: () => File | undefined;
}
interface HiddenFileInputProps {
  accept: string;
  onChange: (file: File | undefined) => void;
}
function HiddenFileInputInner(
  props: HiddenFileInputProps,
  ref: React.Ref<HiddenFileInputRef>
) {
  React.useImperativeHandle(ref, () => ({
    trigger: () => {
      fileInputRef.current?.click();
      return fileInputRef?.current?.files?.[0];
    },
  }));
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const onChange = React.useCallback(() => {
    const file = fileInputRef.current?.files?.[0];
    props.onChange(file);
  }, [fileInputRef.current, props.onChange]);
  // TODO: where to put this portal?
  return ReactDOM.createPortal(
    <input
      ref={fileInputRef}
      type="file"
      accept={props.accept}
      onChange={onChange}
      style={{ display: "none" }}
    />,
    document.body
  );
}
const HiddenFileInput = React.forwardRef(HiddenFileInputInner);
