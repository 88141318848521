import { faFileVideo } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebounceCallback } from "@react-hook/debounce";
import React from "react";
import { useTheme } from "styled-components";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  sublerChapterTextGeneratorSelector,
  setSctgVideoPlaybackTimestampMs,
} from "../../../store/apps/subler-chapter-text-generator/subler-chapter-text-generator-slice";

export interface SctgEditorVideoViewerRef {
  addCurrentTimeMs: (durationMs: number) => void;
}

function SctgEditorVideoViewerInner(
  _props: {},
  ref: React.Ref<SctgEditorVideoViewerRef>
) {
  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useImperativeHandle(
    ref,
    () => ({
      addCurrentTimeMs: (durationMs) => {
        if (!videoRef.current) {
          throw "No current video is available";
        }
        videoRef.current.currentTime += durationMs / 1000;
      },
    }),
    [videoRef.current]
  );

  const { sctgVideoPlaybackTimestampMs, sctgVideoUrl } = useAppSelector(
    sublerChapterTextGeneratorSelector
  );

  const dispatch = useAppDispatch();

  const onVideoTimeUpdate = React.useCallback(
    (e: React.SyntheticEvent<HTMLVideoElement>) => {
      const { currentTime } = e.target as HTMLVideoElement;
      const currentTimeMs = Math.floor(currentTime * 1000);
      dispatch(setSctgVideoPlaybackTimestampMs(currentTimeMs));
    },
    [dispatch, setSctgVideoPlaybackTimestampMs]
  );

  const roundDownTimestamp = React.useCallback(
    (e: React.SyntheticEvent<HTMLVideoElement>) => {
      const video = e.target as HTMLVideoElement;
      if (video.paused) {
        const { currentTime } = video;
        const truncated = Math.floor(currentTime * 1000);
        video.currentTime = truncated / 1000;
        dispatch(setSctgVideoPlaybackTimestampMs(truncated));
      }
    },
    [dispatch, setSctgVideoPlaybackTimestampMs]
  );

  const roundDownTimestampDebounced = useDebounceCallback(
    roundDownTimestamp,
    250,
    false
  );

  // Restore video playback state on browser back/forward
  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = sctgVideoPlaybackTimestampMs / 1000;
    }
  }, []);

  if (!sctgVideoUrl) {
    return <SctgEditorVideoPlaceholder />;
  }

  return (
    <video
      ref={videoRef}
      src={sctgVideoUrl}
      controls={true}
      controlsList="nodownload nofullscreen noremoteplayback"
      onTimeUpdate={onVideoTimeUpdate}
      onPause={roundDownTimestamp}
      onSeeked={roundDownTimestampDebounced}
      style={{
        display: "block",
        margin: "0 auto",
        textAlign: "center",
        maxWidth: "100%",
        maxHeight: "max(400px, 80vh)",
      }}
    />
  );
}

export const SctgEditorVideoViewer = React.forwardRef(
  SctgEditorVideoViewerInner
);

function SctgEditorVideoPlaceholder() {
  const theme = useTheme();
  return (
    // TODO: better placeholder div
    <div
      style={{
        width: 800,
        maxWidth: "100%",
        paddingBottom: "56.25%",
        minHeight: 200,
        background: theme.colors.palette.background.toString(),
        position: "relative",
      }}
    >
      <span
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          color: theme.colors.palette.foreground.toString(),
        }}
      >
        <FontAwesomeIcon
          icon={faFileVideo}
          size="xl"
          style={{ marginBottom: 4 }}
        />
        <div>Upload a video to begin</div>
      </span>
    </div>
  );
}
